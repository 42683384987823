import React, {
    PropsWithChildren,
    useEffect,
    useState,
    useCallback,
    FormEvent,
    useContext,
    SetStateAction,
    Dispatch,
    CSSProperties,
} from 'react';
import {
    DatePicker,
    IDatePicker,
    defaultDatePickerStrings,
} from '@fluentui/react';
import {
    setStartDateInLocalStorage,
    getStartDateInLocalStorage,
    setEndDateInLocalStorage,
    getEndDateInLocalStorage,
} from '../util/localStorageUtils';

interface IDateRangeDropDownProps extends PropsWithChildren<any> {
    setStartDate?: Dispatch<SetStateAction<string | undefined>>;
    setEndDate?: Dispatch<SetStateAction<string | undefined>>;
    isStartDate?: boolean;
}

export const DateRangeDropDown: React.FC<IDateRangeDropDownProps> = (
    props: IDateRangeDropDownProps
) => {
    const datePickerRef = React.useRef<IDatePicker>(null);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();

    const onSelectDate = (date: Date | null | undefined) => {
        if (props.isStartDate && props.setStartDate) {
            if (date) {
                props.setStartDate(date.toUTCString());
                setStartDateInLocalStorage(date.toUTCString());
            } else {
                props.setStartDate(undefined);
                setStartDateInLocalStorage('');
            }
        } else if (!props.isStartDate && props.setEndDate) {
            if (date) {
                props.setEndDate(date.toUTCString());
                setEndDateInLocalStorage(date.toUTCString());
            } else {
                props.setEndDate(undefined);
                setEndDateInLocalStorage('');
            }
        }
    };

    const divStyles: CSSProperties = {
        marginTop: '5px',
        marginLeft: '5px',
        marginRight: '5px',
    };

    //On Page Load up get the dates from storae and add them to the selection
    useEffect(() => {
        if (props.isStartDate) {
            const startDateInStorage =
                getStartDateInLocalStorage() === undefined
                    ? undefined
                    : new Date(getStartDateInLocalStorage()!);
            setStartDate(startDateInStorage);
            onSelectDate(startDateInStorage);
        } else {
            const endDateInStorage =
                getEndDateInLocalStorage() === undefined
                    ? undefined
                    : new Date(getEndDateInLocalStorage()!);
            setEndDate(endDateInStorage);
            onSelectDate(endDateInStorage);
        }
    }, []);

    return (
        <div style={divStyles}>
            {props.isStartDate ? (
                <DatePicker
                    componentRef={datePickerRef}
                    placeholder="Search Range Start"
                    allowTextInput
                    value={startDate}
                    ariaLabel="Select a Date"
                    onSelectDate={onSelectDate}
                    strings={defaultDatePickerStrings}
                />
            ) : (
                <DatePicker
                    componentRef={datePickerRef}
                    placeholder="Search Range End"
                    allowTextInput
                    value={endDate}
                    ariaLabel="Select a Date"
                    onSelectDate={onSelectDate}
                    strings={defaultDatePickerStrings}
                />
            )}
        </div>
    );
};

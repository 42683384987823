import React, { useContext, useRef, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { SiteHeader } from './HubLayout/components/PageLayout/SiteHeader';
import { PageNav } from './HubLayout/components/PageLayout/PageNav';
import { HomePage } from './HubLayout/components/HomePage';
import { FeaturesHomePage } from './AGSS/components/Features/FeaturesHomePage';
import { ServiceListPage } from './ABSM/components/ServiceListPage';
import { ServiceDetailsPage } from './ABSM/components/ServiceDetailsPage';
import { ArtifactSearchResults } from './AGSS/components/Artifact/ArtifactSearchResultsPage';
import { CapabilityContext } from './Common/components/Capabilities/CapabilityContext';
import { Capability } from './Common/components/Capabilities/capability';
import { getThemeFromString } from './Common/util/localStorageUtils';
import { ThemeContext } from './HubLayout/models/ThemeContext';
import { ReleaseLatestListPage } from './AGSS/components/MultistageReleaseData/ReleaseLatestListPage';
import { ReleaseDetailsPage } from './AGSS/components/MultistageReleaseData/ReleaseDetailsPage';
import { BuildInstanceDetailsPage } from './AGSS/components/Build/BuildInstanceDetailsPage';
import { ArtifactDetailsPage } from './AGSS/components/Artifact/ArtifactDetailsPage';
import { Administration } from './Administration/components/Administration';
import { EgressMessageBar } from './HubLayout/components/MessageBars/EgressMessageBar';
import { GeneralMessageBar } from './HubLayout/components/MessageBars/GeneralMessageBar';
import { ServiceTreeMessageBar } from './HubLayout/components/MessageBars/ServiceTreeMessageBar';
import { useIsMobile } from './Common/hooks/useIsMobile';
import { MobilePageNav } from './HubLayout/components/PageLayout/Mobile/MobilePageNav';
import { BuildListPage } from './AGSS/components/MultistageReleaseData/BuildListPage';

export const HubApp: React.FC = () => {
    const [scrollTop, setScrollTop] = useState<number>(0);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        setScrollTop(event.currentTarget.scrollTop);
    };

    // Use capabilities context
    const capabilities = useContext(CapabilityContext);
    // Only show metrics report and latest egress date in public cloud
    const inPublicCloud = capabilities.check(Capability.public);
    // Only show the service-oriented pages if user has Beta UI role and we are ready to show them
    const showServiceOrientedPages = false; //capabilities.check(Capability.betaUIFeaturesRole);

    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const isMobile = useIsMobile();
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
    const showPanel = () => setIsPanelOpen(true);
    const hidePanel = () => setIsPanelOpen(false);

    const scrollBarRef = useRef<HTMLDivElement>(null);

    return (
        <div className="HubApp-root">
            <SiteHeader
                showSearch={capabilities.check(
                    Capability.artifactRereplicateRole
                )}
                openMobileNav={showPanel}
            />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    alignItems: 'stretch',
                    width: '100%',
                    height: 'calc(100% - 60px)',
                }}
            >
                {!isMobile && <PageNav />}
                {isMobile && (
                    <MobilePageNav
                        isOpen={isPanelOpen}
                        dismissPanel={hidePanel}
                    />
                )}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        flex: 1,
                        height: '100%',
                        width: '0px',
                    }}
                >
                    {<GeneralMessageBar />}
                    {inPublicCloud && <EgressMessageBar />}
                    {<ServiceTreeMessageBar />}
                    <div
                        ref={scrollBarRef}
                        className="HubApp-content"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            alignItems: 'stretch',
                            backgroundColor: theme.palette.neutralLighterAlt,
                            padding: isMobile ? '0px' : undefined,
                        }}
                        onScroll={handleScroll}
                    >
                        <Switch>
                            <Route path="/" exact component={HomePage} />
                            {/* Multi-stage Release Pages */}
                            <Route
                                path="/ReleaseStatus/Artifact/Search/:artifactId?"
                                component={ArtifactSearchResults}
                            />
                            <Route
                                path="/ReleaseStatus/Artifact/:artifactId"
                                component={ArtifactDetailsPage}
                            />
                            <Route
                                path="/ReleaseStatus/Build/:buildInstanceCorrelationId"
                                component={BuildInstanceDetailsPage}
                            />
                            <Route
                                path="/ReleaseStatus/Release/:releaseCorrelationId"
                                component={ReleaseDetailsPage}
                            />
                            <Route
                                path="/ReleaseStatus/Latest/:count?"
                                render={(props) => (
                                    <ReleaseLatestListPage
                                        {...props}
                                        scrollBarRef={scrollBarRef}
                                        scrollTop={scrollTop}
                                    />
                                )}
                            />
                            {/* Build Pages */}
                            <Route
                                path="/BuildStatus"
                                component={BuildListPage}
                            />

                            {/* Service-Oriented Pages */}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Services/:serviceId/Artifacts/:artifactId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Service'}
                                            subPageType={'ArtifactDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Services/:serviceId/Artifacts"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Service'}
                                            subPageType={'ArtifactList'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Services/:serviceId/Builds/:buildInstanceCorrelationId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Service'}
                                            subPageType={'BuildDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Services/:serviceId/Builds"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Service'}
                                            subPageType={'BuildList'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Services/:serviceId/Releases/:releaseCorrelationId/Build/:buildInstanceCorrelationId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Service'}
                                            subPageType={'ReleaseBuildDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Services/:serviceId/Releases/:releaseCorrelationId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Service'}
                                            subPageType={'ReleaseDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Services/:serviceId/Releases"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Service'}
                                            subPageType={'ReleaseList'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Services/:serviceId/Components"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Service'}
                                            subPageType={'ComponentList'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Services/:serviceId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Service'}
                                            subPageType={'Overview'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Components/:serviceId/Artifacts/:artifactId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Component'}
                                            subPageType={'ArtifactDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Components/:serviceId/Artifacts"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Component'}
                                            subPageType={'ArtifactList'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Components/:serviceId/Builds/:buildInstanceCorrelationId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Component'}
                                            subPageType={'BuildDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Components/:serviceId/Builds"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Component'}
                                            subPageType={'BuildList'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Components/:serviceId/Releases/:releaseCorrelationId/Build/:buildInstanceCorrelationId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Component'}
                                            subPageType={'ReleaseBuildDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Components/:serviceId/Releases/:releaseCorrelationId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Component'}
                                            subPageType={'ReleaseDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Components/:serviceId/Releases"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Component'}
                                            subPageType={'ReleaseList'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Components/:serviceId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Component'}
                                            subPageType={'Overview'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Services"
                                    render={() => (
                                        <ServiceListPage
                                            serviceType={'Service'}
                                            title={'Services'}
                                            isSubcomponent={false}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Organizations/:serviceId/Artifacts/:artifactId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Organization'}
                                            subPageType={'ArtifactDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Organizations/:serviceId/Artifacts"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Organization'}
                                            subPageType={'ArtifactList'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Organizations/:serviceId/Builds/:buildInstanceCorrelationId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Organization'}
                                            subPageType={'BuildDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Organizations/:serviceId/Builds"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Organization'}
                                            subPageType={'BuildList'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Organizations/:serviceId/Releases/:releaseCorrelationId/Build/:buildInstanceCorrelationId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Organization'}
                                            subPageType={'ReleaseBuildDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Organizations/:serviceId/Releases/:releaseCorrelationId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Organization'}
                                            subPageType={'ReleaseDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Organizations/:serviceId/Releases"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Organization'}
                                            subPageType={'ReleaseList'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Organizations/:serviceId/Services"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Organization'}
                                            subPageType={'ServiceList'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Organizations/:serviceId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Organization'}
                                            subPageType={'Overview'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Organizations"
                                    render={() => (
                                        <ServiceListPage
                                            serviceType={'Organization'}
                                            title={'Organizations'}
                                            isSubcomponent={false}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Divisions/:serviceId/Artifacts/:artifactId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Division'}
                                            subPageType={'ArtifactDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Divisions/:serviceId/Artifacts"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Division'}
                                            subPageType={'ArtifactList'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Divisions/:serviceId/Builds/:buildInstanceCorrelationId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Division'}
                                            subPageType={'BuildDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Divisions/:serviceId/Builds"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Division'}
                                            subPageType={'BuildList'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Divisions/:serviceId/Releases/:releaseCorrelationId/Build/:buildInstanceCorrelationId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Division'}
                                            subPageType={'ReleaseBuildDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Divisions/:serviceId/Releases/:releaseCorrelationId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Division'}
                                            subPageType={'ReleaseDetails'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Divisions/:serviceId/Releases"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Division'}
                                            subPageType={'ReleaseList'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Divisions/:serviceId/Organizations"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Division'}
                                            subPageType={'OrganizationList'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Divisions/:serviceId"
                                    render={() => (
                                        <ServiceDetailsPage
                                            serviceType={'Division'}
                                            subPageType={'Overview'}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Divisions"
                                    render={() => (
                                        <ServiceListPage
                                            serviceType={'Division'}
                                            title={'Divisions'}
                                            isSubcomponent={false}
                                        />
                                    )}
                                />
                            )}
                            {showServiceOrientedPages && (
                                <Route
                                    path="/Features"
                                    exact
                                    component={FeaturesHomePage}
                                />
                            )}
                            <Route
                                path="/Administration"
                                component={Administration}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
};

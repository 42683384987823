import React, { useContext } from 'react';
import { Icon, IconButton, Stack, Text, Link } from '@fluentui/react';
import { ThemeContext } from '../../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../../Common/util/localStorageUtils';
import { TextWithTooltip } from '../../../../../Common/components/TextWithTooltip';
import { getStatusIconProperties } from '../../../../models/Timeline';

interface IFlightDataDisplay {
    name: string;
    cloud: string;
    description: string;
    timestamp: string;
    status: string;
    statusDetails: string;
    isProjected?: boolean;
    isValid?: boolean;
    shouldDisplay: boolean;
}

export const FlightDataDisplay: React.FC<IFlightDataDisplay> = (
    props: IFlightDataDisplay
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const maxStatusDetailsLength = 30;
    const truncateStatusDetails = (details: string) => {
        return details.length > maxStatusDetailsLength ? details.substring(0, maxStatusDetailsLength) + "..." : details;
    };

    const isValidHyperlink = (input: string) => {
        const regex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i;
        return regex.test(input);
    }

    const copyToClipboard = (data?: string) => {
        if (data) {
            navigator.clipboard.writeText(data);
        }
    };

    return (
        <div className="FlightDataDisplay-root">
            {props.shouldDisplay && (
                <Stack horizontal style={{alignItems:'top'}}>
                    <Icon
                        style={{
                            padding: '5px 10px 0 0',
                            color: getStatusIconProperties(props.status, theme)
                                .iconColor,
                        }}
                        iconName={
                            getStatusIconProperties(props.status, theme)
                                .iconName
                        }
                        title={props.status}
                        ariaLabel={'Status icon'}
                    />
                    <Stack>
                        <TextWithTooltip
                            tooltip={props.description}
                            data={
                                props.name +
                                (props.cloud ? ' (' + props.cloud + ')' : '')
                            }
                        />
                         
                        {
                            props.statusDetails != null ?
                                <Stack horizontal style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    {
                                        isValidHyperlink(props.statusDetails)
                                            ?
                                            <Link href={props.statusDetails} underline target='_blank'>
                                                {truncateStatusDetails(props.statusDetails)}
                                            </Link> 
                                            :
                                            <Text style={{ color: 'rgb(0, 120, 212)' }}>{truncateStatusDetails(props.statusDetails)}</Text>
                                    }

                                    <IconButton
                                        iconProps={{ iconName: 'Copy' }}
                                        onClick={() => copyToClipboard(props.statusDetails)}
                                        title={'Copy to clipboard'}
                                    />
                                </Stack> :
                                null
                        }
                        <Text variant="medium">
                            {props.isProjected ? '' : props.timestamp}
                        </Text>
                    </Stack>
                </Stack>
            )}
        </div>
    );
};
